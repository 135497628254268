@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;500&display=swap');

* {
    font-family: 'Mulish', sans-serif;
}
body {
    background: #f6f1e9;
}
.middle-nav-lists ul, .login-modal ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

.bx {
    cursor: pointer;
}

.btn {
    background: #ffffff;
    color: #dca1a1;
    border: #dca1a1 2px solid;
}

.btn-solid {
    background: #dca1a1;
    color: #ffffff;
    /* font-size: 20px; */
    font-weight: bold;
    margin: 0 10px;
}
.btn:hover, .btn:focus {
    background: #dca1a1;
    color: #ffffff;
    box-shadow: 1px 2px 6px #dca1a1;
}
.navbar {
    background-color: #ffffff;
}

.nav-item .nav-link {
    font-size: 15px;
    font-weight: 500;
    color: #676767;
}

.top-bar .nav-link {
    font-size: 30px;
}

.active-link {
    border-bottom: #c34e4e 2px solid;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    z-index: 10000;
}

.middle-bar .nav-link:after {
    content: '';
    display: block;
    margin: auto;
    height: 3px;
    width: 0px;
    background: transparent;
    transition: width .2s ease-out, background-color .2s ease;
}
.middle-bar .nav-link:hover:after {
    width: 100%;
    background: #dca1a1;
}
.middle-bar .middle-nav-lists {
    position: fixed;
    padding: 50px 20px;
    background-color: #ffffff;
    display: none;
}
.middle-bar .nav-item:hover .middle-nav-lists {
    display: block;
}
.middle-bar .middle-nav-lists ul {
    display: inline-block;
    text-align: left;
}
.middle-bar .middle-nav-lists ul li {
    padding-bottom: 10px;
}
.middle-bar .middle-nav-lists ul li a {
    color: #dca1a1;
}


.bottom-bar {
    padding: 6px 0;
    background-color: #d4f3b7;
}
.bottom-bar p {
    color: #222222;
    margin: 0;
}

.bottom-bar .owl-carousel {
    color: white;
    width: 400px;
    margin: auto;
    text-align: center;
}

/* sidebar */

.div1 {
    display: none;
    position: relative;
    width: 25px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 3;
}
  
.sub1, .sub2, .sub3 {
    width: 25px;
    height: 3px;
    margin-bottom: 5px;
    background-color: #dca1a1;
    border-radius: 2px;
    transition: all .2s ease-in-out;
}
  
.div1.cross div {
    background: #dca1a1;
}
  
.div1.cross .sub2{
    background: transparent;
    box-shadow: none;
    transition: all 0.2s ease-in-out;
}
  
.div1.cross .sub1 {
    transform: rotate(45deg) translate(4px, 4px);
}
  
.div1.cross .sub3 {
    transform: rotate(-45deg) translate(7px, -7px);
}

.sidebar {
    position: fixed;
    z-index: 2;
    height: 102vh;
    width: 100vw;
    top: 0;
    left: -200%;
    transition: 0.3s;
    overflow: hidden;
}
.sidebar.active {
    left: 0;
    transition: 0.3s;
}

.sidebar-bg {
    background-color: rgba(0,0,0,0);
    z-index: 1;
    height: 100%;
    width: 100%;
}

.sidebar .container {
    background: #f7f7f7;
    position: absolute;
    width: 75vw;
    height: 100%;
    padding: 100px 50px;
    opacity: 1;
    z-index: 2;
    overflow: auto;
}

.sidebar  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.sidebar ul li {
    font-size: 28px;
    color: #676767;
    font-weight: 550;
}

.sidebar ul li h3 {
    /* margin: 10px 0; */
    padding: 0;
}
.sidebar ul li .subMenu {
    position: unset;
    background: none;
    box-shadow: none;
    border: none;
    display: block;
    margin-top: -20px;
}
.sidebar ul li .subMenu ul {
    display: block;
    margin: 10px;
}
.sidebar ul li .subMenu li {
    font-size: 12px;
}


.none {
    display: none;
}

.show {
    display: block;
}


/* sidebar ends */

.login-modal {
    background: #ffffff;
    padding: 10px 40px;
}

.login-modal ul {
    background-color: #eeeeee;
    width: 300px;
    margin: 30px auto;
    border-radius: 20px;
    padding: 0;
}

.login-modal ul li {
    cursor: pointer;
    display: inline-block;
    padding: 10px 20px;
    border-radius: 20px;
    width: 50%;
    text-align: center;
}
.login-modal ul li.active {
    background-color: #dca1a1;
}
.login-modal .form-control {
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 16px;
}
.carousel .owl-stage-outer {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0,0,69,0.4);
}
.carousel img {
    max-width: 100%;
    max-height: 100%;
}

.owl-nav button {
    position: absolute;
    top: 50%;
    transition: all 0.3s ease-in-out;
}
.carousel .owl-nav button span {
    background-color: rgba(69,69,69,0.4);
    color: #ffffff;
    padding: 10px 10px;
    font-size: 26px;
}
.carousel .owl-nav button span:hover {
    background-color: rgba(255,0,69,0.8);
}
.owl-nav button.owl-prev {
    left: 0;
  }
.owl-nav button.owl-next {
    right: 0;
}

.owl-dots {
    text-align: center;
    padding-bottom: 5px;
}
.owl-dots button.owl-dot {
    width: 16px;
    height: 5px;
    border-radius: 5px;
    display: inline-block;
    background: #454545!important;
    margin: 0 3px;
}
.owl-dots button.owl-dot.active {
    background-color: #dca1a1!important;
}
.owl-dots button.owl-dot:focus {
    outline: none;
}
.owl-dots button.owl-dot:focus {
    outline: none;
}
.owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
span {
    position: relative;
    top: -5px;
}
.owl-nav button:focus {
    outline: none;
}

section h2 {
    color: #B87333;
}

#specialities .container .row .col {
    width: 23%;
    background-color: #dca1a1;
    margin: 20px 0;
    border-radius: 10px;
    border: 1px solid #dca1a1;
    padding: 10px;
    margin-left: 20px;
    text-align: center;
    font-size: 18px;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;
}
#specialities .container .row .col:hover {
    background-color: #ffffff;
    color: #dca1a1;
    border-radius: 1px;
    box-shadow: 0px 0px 10px rgba(255,0,69,0.5);
}

#products .container {
    background: #ffffff;
    padding: 32px;
}

#products .row img {
    height: 400px;
}

#products .container a{
    color: #222222;
}

.product-page {
    background: #ffffff;
    padding: 10px;
}
.product-page .sideimg {
    height: 120px;
}

.disPrice {
    font-size: 26px;
    font-weight: bold;
}
.disPrice span {
    background: #00ce0e;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 6px;
}
.main-price {
    color: #aeaeae;
    font-size: 18px;
    text-decoration: line-through;
}

.sizes ul {
    list-style-type: none;
    padding: 0;
}

.sizes ul li {
    cursor: pointer;
    font-size: 12px;
    display: inline-block;
    padding: 6px;
    border: solid 1px #aeaeae;
    border-radius: 10px;
    margin-right: 4px;
    color: #676767;
    font-weight: bold;
}

.sizes ul li:hover, .sizes ul li.active {
    background-color: #dca1a1;
    color: #ffffff;
    border: solid 1px #dca1a1;
}
.proddesc {
    border-top: 1px solid #cccccc;
    padding: 16px 0;
}

.category-page .row .col-lg-2, .category-page .row .col-lg-9 {
    height: 100vh;
    background: #ffffff;
    margin: 0 5px;
}

.category-page .row .filters {
    padding: 10px 20px;
}


#footer {
    margin-top: 50px;
    background-color: #ffffff;
}
.footer {
    padding: 40px 0;
}

.single_footer{
    padding-bottom: 20px;
}

.cart-page {
    background-color: #ffffff;
    padding: 10px 30px;
}

.item-page .row {
    background-color: #ededed;
    padding: 10px 0;
    border-radius: 10px;
}

.item-page .row .col-lg-2 img {
    width: 100%;
    border-radius: 10px;
}

.item-page .row .col-lg-3 .no-items button {
    background-color: #e3e3e3;
    color: #000000;
    font-size: 20px;
    border: 1px solid #454545;
    height: 25px;
    text-align: center;
}

@media only screen and (max-width:768px) { 
.single_footer{margin-bottom:30px;}
}
.single_footer h4 {
    color: #222222;
    margin-top: 0;
    margin-bottom: 16px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
}
.single_footer p{
    color: #222222;
}
.single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.single_footer ul li{}
.single_footer ul li a {
    color: #454545;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 32px;
    font-size: 12px;
    text-transform: capitalize;
}
.single_footer ul li a:hover { color: #dca1a1; }

.single_footer_address{}
.single_footer_address ul{}
.single_footer_address ul li{color:#222222;}
.single_footer_address ul li span {
    font-weight: 400;
    color: #222222;
    line-height: 28px;
}
.contact_social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/* .social_profile {margin-top:40px;} */
.social_profile ul{
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.social_profile ul li{float:left;}
.social_profile ul li a {
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin-right: 8px;
    font-size: 15px;
    background: #dca1a1;
    color: #ffffff;
    border-radius: 6px;
    width: 25px;
    height: 25px;
    line-height: 25px;
    display: block;
    border: 1px solid rgba(255,255,255,0.2);
}
@media only screen and (max-width:768px) {
    .social_profile ul li a{
        margin-right:10px;
        margin-bottom:10px;
    }
}
@media only screen and (max-width:480px) { 
    .social_profile ul li a{
        width:40px;
        height:40px;
        line-height:40px;
    }
}
.social_profile ul li a:hover{
    background:#ffffff;
    border: 1px solid #dca1a1;
    color:#dca1a1;
}
/*END SOCIAL PROFILE CSS*/
.copyright {
    padding-top: 20px;
    color:#454545;
    font-size: 15px;
    margin: 0;
    border-top: 1px solid #cccccc;
}
.copyright a{color:#01c7e9;transition: all 0.2s ease 0s;}
.copyright a:hover{color:#dca1a1;}