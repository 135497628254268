@media (max-width: 1200px) {
    nav .nav {
        display: none;
    }
    .div1 {
        display: block;
    }
    .logoContainer img {
        width: 80px;
        margin-left: 30px;
    }

}