:root{
    --hoverTextColor:rgb(244, 51, 151);
    --textColor : rgb(87, 85, 85);
}
img {
    width: 100%;
}
.main-head {
    /* background: linear-gradient(#ffabc1,#dca1a1); */
    background: #ffffff;
    /* border-top: 6px solid #dca1a1; */
    /* border-bottom: 6px solid #dca1a1; */
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}
.header{
    height: 90px;
    /* outline: 1px solid #ccc; */
    padding: 0px 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.headerLeft{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.headerRight{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.logoContainer{
    /* background: #ffffff; */
    /* box-shadow: 0px 0px 5px rgba(0,0,0,0.3); */
    margin-right: 60px;
    /* width: 180px; */
    /* padding: 10px; */
}
.logoContainer .logo-text {
    color: #dca1a1;
    font-weight: bold;
}

.logoContainer img{
    /* width: fit-content; */
    width: 160px;
}

.searchInputContainer{
    background: #ffffff;
    width: 450px;
    height: 36px;
    outline:1px solid #454545;
    display: flex;
    /* margin-right: 25px; */
    border-radius: 5px;
    align-items: center;
    padding: 4px 4px 4px 10px;
    position: relative;
}
.searchIcon{
    color: #dca1a1;
    width: 20px;
    margin: 10px 2px 6px;
}
form{
    width: 100%;
}
.inputSearch{
    width: 100%;
    border:none;
    outline: none;
    font-size: 16px;
}
#closeSearch{
    display: none;
}


.searchRecentModal{
    position: absolute;
    bottom: 0;
    left:0;
    top:32px;
    background-color: white;
    height: 320px;
    width: 100%;
    display: none;
    padding: 15px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    z-index: 3;
}
.searchInputContainer:focus-within .searchRecentModal{
    display: block;
    background-color: white;
}
.recentIcon{
    width: 20px;
    margin-right: 10px;
}
.recentItem{
    display: flex;
    align-items: center;
    background-color: white;
}

.mobileIcon{
    width: 15px;
    margin: 5px 0;
    margin-right: 5px;
}
.downloadContainer{
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0px 30px;
    border-left: 1.5px solid #ccc;
    border-Right: 1.5px solid #ccc;
    position: relative;
}
.downloadContainer:hover{
    color:var(--hoverTextColor);
    cursor: pointer;
}
.downloadBtn{
    display: block;
    width: 180px;
    margin-top: 5px;
}
.downloadHoverBtnContainer{
    position: absolute;
    bottom: 0;
    top:34px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    width: 200px;
    display: none;
    height: max-content;
    padding: 10px;
    margin-left: -30px;
    color:var(--textColor);
    background: white;
    z-index: 2;
}
.downloadContainer:hover .downloadHoverBtnContainer,
.downloadContainer:focus .downloadHoverBtnContainer{
    display: block;
}

.downloadContainer p{
    margin:0;
    padding: 0;
    font-size: 17px;
}

.profileAndCart{
    display: flex;
    align-items: center;
    
}

.profileIcon, .CartIcon{
    margin-bottom: -6px;
    font-size: 22px;
    /* width: 32px; */
}
.profileAndCart p{
    margin:0;
    padding: 0;
    font-size: 12px;
}
.searchcont, .profileContainer, .CartContainer{
    text-decoration: none;
    color:var(--textColor);
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.searchcont:hover, .profileContainer:hover, .CartContainer:hover{
    color: var(--hoverTextColor);
    cursor: pointer;
}

.searchcont{
    display: flex;
    align-items: center;
    position: relative;
}
.searchHoverContainer{
    position: absolute;
    bottom: 0;
    top:62px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.3);
    width: 490px;
    display: none;
    height: max-content;
    padding: 20px;
    margin-left: -400px;
    color:var(--textColor);
    background: white;
    cursor: default;
    z-index: 2;
}
.searchcont:hover .searchHoverContainer,
.searchcont:focus .searchHoverContainer{
    display: block;
}


nav{
    width: 100%;
    /* border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc; */
    height: 40px;
}
nav ul{
    padding: 0;
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    height: 100%;
    margin: 0; 
    position: relative;
}
nav ul li{
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 12px;
    /* border-Right: 1.5px solid #ccc; */
    /* background-color: red; */
    border-bottom: 2px solid transparent;
    cursor: pointer; 
    
}
nav ul li:hover{
    color:var(--hoverTextColor);
    border-bottom: 4px solid var(--hoverTextColor);
}


/**module box submenu***/
.subMenu{
    position: absolute;
    /* height: 400px; */
    top:42px;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: none;
    margin: 0px 0px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.3);
    padding: 20px;
}

nav ul li:hover .subMenu{
    display: block;
}

.subMenu p{
    color:var(--textColor)

}
.submenuList{
    /* display: flex; */
}
.submenuList ul{
    display: inline-block;
    width: 200px;
}
.submenuList ul li{
    padding: 2px 12px;
}
.submenuList ul li:hover{
    color:var(--hoverTextColor);
    border-bottom: 0px solid var(--hoverTextColor);
}
.submenuList ul li a {
    color: #454545;
}
.submenuList ul li a:hover{
    color: #dca1a1;
}
.column{
    margin-right: 40px;
}